import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Space, Form, Select, Tag, Row, Col } from 'antd';
import { IModal } from 'common/models/model.interface';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as MoveOut } from 'assets/icons/move-white.svg';
import { IAddress } from 'common/services/street-service/street.service.types';
import AddressSelect from 'features/eiq-manage/pages/eiq-manage-residents/components/address-select/address-select';
import { IOccupantDetails, IOccupantMoveModalProps, IResidentMoveModalProps } from './resident-move-modal.types';
import {
  IAccessCardRead,
  IMoveResident,
  IPetRead,
  IResidentAddressRead,
  IVehicleRead,
} from 'common/services/residents-service/residents.service.types';
import residentStore from 'features/eiq-manage/stores/resident.store';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import {
  residentMoveModalOccupantsInitialColumns,
  residentMoveModalPetsInitialColumns,
  residentMoveModalVehiclesInitialColumns,
} from './resident-move-modal-tables.config';
import TableBase from 'common/components/table/table-base';
import addressesService from 'common/services/addresses-service/addresses.service';
import './resident-move-modal.scss';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import residentsService from 'common/services/residents-service/residents.service';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import OccupantsMoveModal from './occupants-move-modal';
import { baseModalTableHeightConfig, calculateTableHeight } from 'common/components/table/table-helpers';
import { formatAddressLabel } from '../../../../../../common/helpers/address.helper';
import { notification } from '../../../../../../common/utils/notification';
import { isOccupant } from 'common/helpers/resident-address.helper';
import { accessCardColumns } from '../info-block/access-cards/access-card-list.comfig';
import featureStore from 'common/stores/features/feature.store';

const { Option } = Select;

const ResidentMoveModal: FC<IModal<IResidentMoveModalProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();

  const [addressToId, setAddressToId] = useState<number | null>(null);
  const [addressFromId, setAddressFromId] = useState<number | null>(null);
  const [occupants, setOccupants] = useState<IAddressOccupant[]>([]);
  const [vehicles, setVehicles] = useState<IVehicleRead[]>([]);
  const [pets, setPets] = useState<IPetRead[]>([]);
  const [residentVehicles, setResidentVehicles] = useState<IVehicleRead[]>([]);
  const [accessCards, setAccessCards] = useState<IAccessCardRead[]>([]);
  const [residentPets, setResidentPets] = useState<IPetRead[]>([]);
  const [occupantsDetails, setOccupantsDetails] = useState<IOccupantDetails[]>([]);
  const [occupantsDetailsCache, setOccupantsDetailsCache] = useState<IOccupantDetails[]>([]);
  const [addressSelectResetTrigger, setAddressSelectResetTrigger] = useState<boolean>(false);

  const accessCardsTableControls = useTableControls({
    clientSide: true,
    data: accessCards,
    initialColumns: accessCardColumns,
  });

  const occupantsTableControls = useTableControls({
    clientSide: true,
    data: occupants,
    initialColumns: residentMoveModalOccupantsInitialColumns,
  });

  const vehiclesTableControls = useTableControls({
    clientSide: true,
    data: vehicles,
    initialColumns: residentMoveModalVehiclesInitialColumns,
  });

  const petsTableControls = useTableControls({
    clientSide: true,
    data: pets,
    initialColumns: residentMoveModalPetsInitialColumns,
  });

  const moveOccupantsModal = useModal({
    onBeforeShow: (setModalInitData, data) => {
      const { requestBody, residentId, addressFromId } = data;

      const isNotInSelectedOccupants = (occupant: any) => {
        return !occupantsTableControls
          .getSelectedRows()
          .some((selectedOccupant: any) => selectedOccupant.residentId === occupant.residentId);
      };
      const initData: IOccupantMoveModalProps = {
        notSelectedOccupants: occupants.filter(isNotInSelectedOccupants),
        requestBody,
        residentId,
        addressFromId,
      };
      setModalInitData(initData);
    },
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) {
        hideModal(true);
      }
    },
  });

  useEffect(() => {
    const addressId = residentStore.selectedResidentAddress?.address?.id;
    const residentId = residentStore.selectedResidentAddress?.resident?.id;

    addressesService
      .getOccupantsByAddressId(addressId!)
      .then((res) => {
        setOccupants(
          res
            ?.filter((item) => item?.host?.residentId === residentId && isOccupant(item.ownershipType))
            .map((item, index) => ({
              ...item,
              id: index,
            })),
        );
      })
      .catch((e) => {
        console.error(e);
      });
    const residentVehicles: IVehicleRead[] =
      residentStore.selectedResident?.addresses.reduce((acc, curr) => acc.concat(curr.vehicles as any), []) || [];
    const residentPets: IPetRead[] =
      residentStore.selectedResident?.addresses.reduce((acc, curr) => acc.concat(curr.pets as any), []) || [];
    const residentAccessCards: IAccessCardRead[] =
      residentStore.selectedResident?.addresses.reduce((acc, curr) => acc.concat(curr.accessCards as any), []) || [];
    setResidentVehicles(residentVehicles);
    setResidentPets(residentPets);
    setAccessCards(residentAccessCards);
  }, []);

  useEffect(() => {
    const selectedOccupants = occupantsTableControls.getSelectedRows();

    const getResidentsDetalis = async () => {
      let updatedDetails: IOccupantDetails[] = [];
      let updatedCache: IOccupantDetails[] = occupantsDetailsCache;
      let callCount = 0;

      await Promise.all(
        selectedOccupants.map(async (occupant: any) => {
          try {
            const existingOccupantVehicles = occupantsDetailsCache.find((ov) => ov.id === occupant.residentId);
            if (existingOccupantVehicles) {
              updatedDetails.push({
                id: existingOccupantVehicles.id,
                vehicles: existingOccupantVehicles.vehicles,
                pets: existingOccupantVehicles.pets,
              });
            } else {
              if (callCount < 15) {
                callCount++;
                const res = await residentsService.getResidentDetails(occupant.residentId);
                const occupantVehicles = res.addresses.reduce((acc, curr) => acc.concat(curr.vehicles as any), []) || [];
                const occupantPets = res.addresses.reduce((acc, curr) => acc.concat(curr.pets as any), []) || [];
                updatedDetails.push({ id: occupant.residentId, vehicles: occupantVehicles, pets: occupantPets });
                updatedCache.push({ id: occupant.residentId, vehicles: occupantVehicles, pets: occupantPets });
              }
            }
          } catch {}
        }),
      );
      setOccupantsDetailsCache(updatedCache);
      setOccupantsDetails(updatedDetails);
    };
    getResidentsDetalis();
  }, [occupantsTableControls.getSelectedRows()]);

  useEffect(() => {
    const formattedOccupantVehicles = occupantsDetails
      .map((occupantDetails: any) => {
        return occupantDetails.vehicles;
      })
      .flat();
    const formattedOccupantPets = occupantsDetails
      .map((occupantDetails: any) => {
        return occupantDetails.pets;
      })
      .flat();
    setVehicles([...residentVehicles, ...formattedOccupantVehicles]);
    setPets([...residentPets, ...formattedOccupantPets]);
  }, [occupantsDetails, residentVehicles, residentPets]);

  useEffect(() => {
    if (initData) {
      setAddressFromId(initData?.addressOptions?.[0]?.address?.id);
    }
  }, [initData]);

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    setAddressToId(null);
    setAddressFromId(null);

    if (onClose) {
      onClose(...rest);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const onFinish = async () => {
    if (addressFromId && addressToId) {
      const data: IMoveResident = {
        hostReplacementId: null,
        occupantsToMoveIds: occupantsTableControls.getSelectedRows().map((occupant: any) => occupant.residentId),
        toAddressId: addressToId!,
      };

      if (occupants.length !== occupantsTableControls.getSelectedRows().length) {
        moveOccupantsModal.showModal({ requestBody: data, residentId: initData!.residentId, addressFromId });
      } else {
        await residentsService.moveResidentToAddress(initData!.residentId, addressFromId!, data);
        hideModal(true);
      }
    }
  };

  const handleAddressChange = (value: IAddress | null) => {
    const addressId = value ? value.id : null;
    setAddressToId(addressId);
  };

  useEffect(() => {
    if (isOpen) {
      setAddressSelectResetTrigger(!addressSelectResetTrigger);
    }
  }, [isOpen]);

  return (
    <>
      <Modal centered title={title} width={898} open={isOpen} wrapClassName="custom-modal resident-move-modal" closable={false}>
        <FormWrapper form={form} onFinish={onFinish} layout="vertical">
          <Space className="full-width" size={13}>
            <Form.Item label="Host name">
              <div className="host_name">
                {initData?.firstName} {initData?.lastName}
              </div>
            </Form.Item>
          </Space>
          <Space className="full-width" size={13}>
            <Form.Item>
              <div>Move from</div>
            </Form.Item>
          </Space>
          <Space className="full-width">
            <Form.Item className="eiq-select" label="Address">
              <Select onChange={setAddressFromId} defaultValue={initData?.addressOptions?.[0]?.address?.id}>
                {initData?.addressOptions?.map((option: IResidentAddressRead) => (
                  <Option className="eiq-select-options" key={option.address.id} value={option.address.id}>
                    <Tag color="blue">{option.ownershipType}</Tag>
                    {formatAddressLabel(option.address)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
          <Space className="full-width" size={13}>
            <Form.Item>
              <div>Move to</div>
            </Form.Item>
          </Space>
          <AddressSelect value={addressToId} setAddress={handleAddressChange} isOpen={addressSelectResetTrigger} form={form} />
          {occupants?.length > 0 && (
            <TableContextProvider controls={occupantsTableControls}>
              <Row>
                <Col span={24}>
                  <h3 className="table-title">{'Occupants'}</h3>
                </Col>
              </Row>
              <Row
                className="table-wrapper"
                style={{ height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: occupants!.length }) }}>
                <TableBase selectableWithCheckboxes />
              </Row>
            </TableContextProvider>
          )}
          {vehicles.length > 0 && (
            <TableContextProvider controls={vehiclesTableControls}>
              <Row>
                <Col span={24}>
                  <h3 className="table-title">{'Vehicles'}</h3>
                </Col>
              </Row>
              <Row
                className="table-wrapper"
                style={{ height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: vehicles!.length }) }}>
                <TableBase disableSelection />
              </Row>
            </TableContextProvider>
          )}
          {accessCards.length > 0 && featureStore.isCardAccessEnabled && (
            <TableContextProvider controls={accessCardsTableControls}>
              <Row>
                <Col span={24}>
                  <h3 className="table-title">{'Other Devices'}</h3>
                </Col>
              </Row>
              <Row
                className="table-wrapper"
                style={{ height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: accessCards!.length }) }}>
                <TableBase disableSelection />
              </Row>
            </TableContextProvider>
          )}
          {pets.length > 0 && (
            <TableContextProvider controls={petsTableControls}>
              <Row>
                <Col span={24}>
                  <h3 className="table-title">{'Pets'}</h3>
                </Col>
              </Row>
              <Row
                className="table-wrapper"
                style={{ height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: pets!.length }) }}>
                <TableBase disableSelection />
              </Row>
            </TableContextProvider>
          )}
          <Space className="footer">
            <Button key="cancel" type="link" onClick={() => hideModal()} className="eiq-button">
              Cancel
            </Button>
            <Button key="residentForm" htmlType="submit" type="primary" className="eiq-button" icon={<MoveOut />}>
              Move
            </Button>
          </Space>
        </FormWrapper>
      </Modal>
      {moveOccupantsModal.isOpenModal && (
        <OccupantsMoveModal
          initData={moveOccupantsModal?.initData}
          title="Move"
          isOpen={moveOccupantsModal?.isOpenModal}
          onClose={moveOccupantsModal?.hideModal}
        />
      )}
    </>
  );
};

export default React.memo(ResidentMoveModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
