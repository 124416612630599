import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { observer } from 'mobx-react-lite';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import InfoField from 'common/components/info-block/info-field/info-field';

import '../accounts.scss';
import { IAccountRead } from 'features/eiq-manage/service/account-service/account.service.types';
import accountService from 'features/eiq-manage/service/account-service/account.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import ChangePasswordModal from './change-password-modal/change-password-modal';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';

export const AccountInfo = observer(() => {
  const defaultConfiguration = configurationStore.configuration?.default;
  const [account, setAccount] = useState<IAccountRead | null>(null);

  useEffect(() => {
    accountService.getCurrentAccount().then((currentAccount) => {
      setAccount(currentAccount);
    });
  }, []);

  const changePasswordModal = useModal();

  const handleChangePasswordClick = () => {
    changePasswordModal.showModal();
  };

  return (
    <>
      <Card className="eiq-card eiq-card-border eiq-configuration">
        <Row className="eiq-card-header">
          <Col span={24}>
            <h1 className="h1">Account information</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <Space className="full-width">
              <InfoField value={defaultConfiguration?.communityName} label="Community" bodyClassName="account-info-field" />
            </Space>

            <Space className="full-width">
              <InfoField value={account?.accountName} label="Account name" bodyClassName="account-info-field" />
            </Space>

            <Space className="full-width">
              <InfoField value={`${account?.firstName} ${account?.lastName}`} label="Full name" bodyClassName="account-info-field" />
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Space className="full-width">
              <InfoField value={account?.phone} label="Phone" bodyClassName="account-info-field" valueIcon={<PhoneIcon />} />
            </Space>

            <Space className="full-width">
              <InfoField value={account?.email} label="Email" bodyClassName="account-info-field" valueIcon={<EmailIcon />} />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button icon={<EditIcon />} size="middle" className="edit-button" onClick={handleChangePasswordClick}>
              Change password
            </Button>
          </Col>
        </Row>
      </Card>
      <ChangePasswordModal isOpen={changePasswordModal?.isOpenModal} onClose={changePasswordModal?.hideModal} />
    </>
  );
});
