import { useLocation, Outlet } from 'react-router-dom';
import React from 'react';
import { Button, Layout, Menu, MenuProps } from 'antd';
import './eiq-secure-layout.scss';
import { SH } from 'common/components';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as PhoneFillIcon } from 'assets/icons/phone-fill.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { PermissionEnum } from '../../../common/enums/permission.enum';
import FindKeyModal from '../components/eiq-secure-find-key-modal/eiq-secure-find-key-modal';
import { useModal } from '../../../common/hooks/use-modal/use-modal.hook';
import LogoIcon from 'assets/icons/eiq-logo.png';
import QRCodeScannerModal from '../components/eiq-secure-qr-code-scanner-modal/eiq-secure-qr-code-scanner-modal';
import { ReactComponent as ScanIcon } from 'assets/icons/scan.svg';
import Feature from 'common/components/feature/feature';
import { FeatureName } from 'common/enums/feature-name.enum';

const { Header, Content } = Layout;

export const EiqSecureLayout = (props: any) => {
  const location = useLocation();
  const hasReadTrafficLogPermission = userAuthStore.permissions?.includes(PermissionEnum.readTrafficLog);

  const findKeyModal = useModal();
  const qrScannerModal = useModal();

  const items: MenuProps['items'] = [
    {
      label: <SH.HotkeyLink to={'/eiq-secure'} label={'Main'} />,
      key: '/eiq-secure',
    },
    {
      label: <SH.HotkeyLink to={'/eiq-secure/keys'} label={'Keys'} />,
      key: '/eiq-secure/keys',
    },
    hasReadTrafficLogPermission
      ? {
          label: <SH.HotkeyLink to={'/eiq-secure/traffic'} label={'Traffic'} />,
          key: '/eiq-secure/traffic',
        }
      : null,

    // {
    //   label: <SH.HotkeyLink to={'/eiq-secure/alert-messages'} hotkey={'alt+A'} keyLabel={'A'} label={'Alert messages'} />,
    //   key: '/eiq-secure/alert-messages',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-secure/function-guests-list'} hotkey={'alt+F'} keyLabel={'F'} label={'Function guests list'} />,
    //   key: '/eiq-secure/function-guests-list',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-secure/reports'} hotkey={'alt+R'} keyLabel={'R'} label={'Reports'} />,
    //   key: '/eiq-secure/reports',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-secure/global-contractor-list'} hotkey={'alt+C'} keyLabel={'C'} label={'Global contractor list'} />,
    //   key: '/eiq-secure/global-contractor-list',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-secure/system-configuration'} hotkey={'alt+S'} keyLabel={'S'} label={'Settings'} />,
    //   key: '/eiq-secure/system-configuration',
    // },
  ];

  return (
    <div className="eiq-secure-panel">
      <Layout>
        <Header>
          <img className="logo" src={LogoIcon} alt="logo" />

          <Menu
            defaultSelectedKeys={[location.pathname]}
            className="cc-menu-item"
            items={items}
            mode="horizontal"
            selectedKeys={[location.pathname]}
          />
          <Feature name={FeatureName.FastAccess}>
            <Button type="ghost" icon={<ScanIcon />} onClick={qrScannerModal.showModal} />
          </Feature>
          <Button type="ghost" icon={<KeyIcon />} onClick={findKeyModal.showModal} />
          <Button type="ghost" icon={<PhoneFillIcon />} />
          <Button type="link" target="_blank" href="https://my.entranceiq.support/" icon={<Help />} />
          <Button
            type="ghost"
            icon={<ExitIcon />}
            onClick={() => {
              userAuthStore.logOut();
            }}
          />
        </Header>
        <Content className="content-style">
          <Outlet />
        </Content>
      </Layout>
      <FindKeyModal isOpen={findKeyModal?.isOpenModal} onClose={findKeyModal?.hideModal} />
      <QRCodeScannerModal isOpen={qrScannerModal?.isOpenModal} onClose={qrScannerModal?.hideModal} />
    </div>
  );
};
