import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from '../../common/components/table/table-context';
import { IResidentAddressRead } from '../../common/services/residents-service/residents.service.types';

import residentsService from '../../common/services/residents-service/residents.service';

import { Button, Card, Col, Row, Space, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';

import eiqSecureStore from './stores/eiq-secure.store';
import { eiqSecureResidentInitialColumns } from './eiq-secure.config';
import { EiqSecureGeneralView } from './components/eiq-secure-general-view/eiq-secure-general-view';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { useMedia } from '../../common/hooks/use-media/use-media.hook';
import './eiq-secure.scss';
import { EiqSecureGuestList } from './components/eiq-secure-guests-list/eiq-secure-guest-list';
import { AdmitGuestEnum, AdmitResidentEnum } from './constans/admit-types';
import InfoBlock from '../../common/components/info-block/info-block';
import { EiqSecureGuestLicense } from './components/eiq-secure-guest-license/eiq-secure-guest-license';
import { AddVisitor } from './components/add-visitor/add-visitor';
import { EiqSecureResidentLicense } from './components/eiq-secure-resident-license/eiq-secure-resident-license';
import configurationStore from 'common/stores/configuration/configuration.store';
import Footer from '../../common/components/footer/footer';
import { EiqSecureResidentsList } from './components/eiq-secure-residents-list/eiq-secure-residents-list';
import { EiqSecureVacantAddressesList } from './components/eiq-secure-vacant-addresses-list/eiq-secure-vacant-addresses-list';
import EiqTabs from '../../common/components/tabs/eiq-tabs';
import { PlusOutlined } from '@ant-design/icons';
import { Residents, VacantAddressesList, VehiclesList } from './constans/eiq-secure-tab-items';
import { EiqSecureVehiclesInfo } from './components/eiq-secure-vehicles-info/eiq-secure-vehicles-info';
import { EiqSecureVehiclesList } from './components/eiq-secure-vehicles-list/eiq-secure-vehicles-list';
import { trackPromise } from 'react-promise-tracker';

export const EiqSecure = observer(() => {
  const [residentLicenseAdmit, setResidentLicenseAdmit] = useState(false);
  const [guestLicenseAdmit, setGuestLicenseAdmit] = useState(false);
  const [activeTab, setActiveTab] = useState(Residents);
  const { isMobileOrTable, isDesktop, isMobile } = useMedia();
  const isShowPinColumn = configurationStore.configuration?.default.isShowPin;
  const { admitFlow } = eiqSecureStore;

  const isVehiclesTab = activeTab === VehiclesList;

  const getEiqSecureGuestInitialColumns = () => {
    if (!isShowPinColumn) {
      return eiqSecureResidentInitialColumns.filter((i) => i.key !== 'pin');
    }
    return eiqSecureResidentInitialColumns;
  };

  const getSelectedAddressOccupants = (residentId?: number, addressId?: number) => {
    if (residentId && addressId) {
      trackPromise(
        residentsService
          .getOccupants(residentId)
          .then((result) => {
            const currentAddressOccupants = result.filter((i) => i.address.id === addressId).filter((i) => i.id !== residentId);
            eiqSecureStore.setSelectedAddressOccupants(currentAddressOccupants);
          })
          .catch((e) => {
            console.error(e);
          }),
      );
    }
  };

  // Table config
  const tableControls = useTableControls({
    onSelectedRow: (selectedRow: IResidentAddressRead) => {
      eiqSecureStore.setSelectedAddressId(selectedRow?.address?.id);
      eiqSecureStore.setSelectedResident(selectedRow?.resident);
      getSelectedAddressOccupants(selectedRow?.resident.id, selectedRow?.address?.id);
    },
    onFetch: residentsService.getResidentAddresses,
    initialColumns: getEiqSecureGuestInitialColumns(),
  });

  useEffect(() => {
    const residentId = eiqSecureStore.selectedResident?.id;
    const addressId = eiqSecureStore.selectedAddressId;

    if (residentId === null || residentId === undefined || addressId === null || addressId === undefined) {
      eiqSecureStore.setResidentDetails(null);
      return;
    }

    residentsService
      .getResidentDetails(residentId)
      .then((data) => {
        eiqSecureStore.setResidentDetails(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [eiqSecureStore.selectedResident?.id, eiqSecureStore.selectedAddressId]);

  const handleAdmitResident = () => {
    eiqSecureStore.setAdmitFlow(AdmitResidentEnum.LICENSE);
  };

  const handleAdmitGuestMobile = () => {
    eiqSecureStore.setAdmitFlow(AdmitGuestEnum.SELECT_GUEST);
  };

  const handleAddVisitor = () => {
    eiqSecureStore.setAdmitFlow(AdmitGuestEnum.ADD);
  };

  const handleAdmitGuest = () => {
    if ((admitFlow === null || admitFlow === AdmitGuestEnum.SELECT_GUEST) && eiqSecureStore.selectedGuest?.id) {
      eiqSecureStore.setAdmitFlow(AdmitGuestEnum.LICENSE);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      const isShiftEnter = event.shiftKey && event.key === 'Enter';
      const isEnter = event.key === 'Enter';
      setGuestLicenseAdmit(false);
      setResidentLicenseAdmit(false);

      if (admitFlow === null) {
        if (isShiftEnter) {
          handleAdmitResident();
        } else if (isEnter) {
          handleAdmitGuest();
        }
      } else if (isEnter) {
        if (admitFlow === AdmitGuestEnum.LICENSE) {
          setGuestLicenseAdmit(true);
        } else if (admitFlow === AdmitResidentEnum.LICENSE) {
          setResidentLicenseAdmit(true);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [eiqSecureStore.admitFlow]);

  const admitButtons =
    admitFlow === null &&
    (activeTab === Residents ? (
      <Space size={12}>
        <Button className="secondary-btn" icon={<AdmitIcon />} onClick={handleAdmitResident}>
          Admit resident
        </Button>
        {isMobileOrTable && (
          <Button type="primary" className="primary-btn" icon={<AdmitIcon />} onClick={handleAdmitGuestMobile}>
            Guest List
          </Button>
        )}
      </Space>
    ) : (
      isMobileOrTable && (
        <Button className="secondary-btn" icon={<PlusOutlined />} onClick={handleAddVisitor}>
          Add Unlisted guest
        </Button>
      )
    ));

  const admitContentFlow = (
    <>
      {isDesktop && admitFlow === null && <EiqSecureGuestList handleAdmitGuest={handleAdmitGuest} />}
      {admitFlow === AdmitResidentEnum.LICENSE && <EiqSecureResidentLicense residentLicenseAdmit={residentLicenseAdmit} />}
      {admitFlow === AdmitGuestEnum.LICENSE && <EiqSecureGuestLicense guestLicenseAdmit={guestLicenseAdmit} />}
      {isMobileOrTable && admitFlow === AdmitGuestEnum.SELECT_GUEST && <EiqSecureGuestList handleAdmitGuest={handleAdmitGuest} />}
      {admitFlow === AdmitGuestEnum.ADD && <AddVisitor isVacantAddress={activeTab === VacantAddressesList} />}
    </>
  );

  useEffect(() => {
    if (activeTab === Residents) {
      eiqSecureStore.setAdmitFlow(null);
      eiqSecureStore.setSelectedGuest(null);
    } else {
      eiqSecureStore.setAdmitFlow(AdmitGuestEnum.ADD);
    }
  }, [isMobileOrTable, isDesktop, tableControls.getSelectedRow()?.id]);

  useEffect(() => {
    if (eiqSecureStore.admitFlow === null && activeTab === Residents) {
      setActiveTab(Residents);
    }
  }, [eiqSecureStore.admitFlow]);

  // [TODO] add a real permission or remove
  const hasAdmitPermission = true;
  const isActionFooter =
    (hasAdmitPermission && admitFlow === null) ||
    (isMobileOrTable && admitFlow === AdmitGuestEnum.SELECT_GUEST) ||
    admitFlow === AdmitGuestEnum.ADMIT_GUEST ||
    admitFlow === AdmitResidentEnum.LICENSE ||
    admitFlow === AdmitGuestEnum.ADD ||
    admitFlow === AdmitGuestEnum.LICENSE;

  const items: TabsProps['items'] = [
    {
      key: Residents,
      label: 'Residents',
      children: <EiqSecureResidentsList handleAdmitResident={handleAdmitResident} />,
    },
    {
      key: VacantAddressesList,
      label: 'Vacant Addresses',
      children: <EiqSecureVacantAddressesList />,
    },
    {
      key: VehiclesList,
      label: 'Vehicles',
      children: <EiqSecureVehiclesList />,
    },
  ];

  const onTabsChange = (item: string) => {
    if (item === VacantAddressesList) {
      setActiveTab(item);
      if (isDesktop) {
        eiqSecureStore.setAdmitFlow(AdmitGuestEnum.ADD);
      } else eiqSecureStore.setAdmitFlow(null);
    } else {
      setActiveTab(item);
      eiqSecureStore.setAdmitFlow(null);
    }
  };

  return (
    <div className="eiq-secure-main-page">
      {isVehiclesTab && (
        <TableContextProvider controls={tableControls}>
          <Card className="eiq-card eiq-card-border full-card">
            <EiqTabs
              onChange={(item) => onTabsChange(item)}
              activeKey={activeTab}
              defaultActiveKey={Residents}
              items={items}
              tabBarGutter={16}
            />
          </Card>
        </TableContextProvider>
      )}
      {!isVehiclesTab && (
        <TableContextProvider controls={tableControls}>
          <Row gutter={16} className="row-h-50 row-h-100-mob-tab">
            <Col className="d-flex" flex="auto" span={12} xs={24} lg={'auto'}>
              <Card className="eiq-card eiq-card-border d-flex so__resident-box">
                <EiqTabs
                  onChange={(item) => onTabsChange(item)}
                  activeKey={activeTab}
                  defaultActiveKey={Residents}
                  items={items}
                  tabBarGutter={16}
                />
                {isMobileOrTable && admitContentFlow}
              </Card>
            </Col>

            {isDesktop && (
              <Col className="d-flex eiq-secure-info" flex="auto" span={12}>
                <Card className="eiq-card eiq-card-border">
                  <EiqSecureGeneralView isVacantAddress={activeTab === VacantAddressesList} />
                </Card>
              </Col>
            )}
          </Row>

          {isMobileOrTable && (
            <InfoBlock>
              <EiqSecureGeneralView isVacantAddress={activeTab === VacantAddressesList} />
            </InfoBlock>
          )}

          {isDesktop && (
            <Row className="row-h-50 address-row">
              <Col flex="auto" className="d-flex">
                <Card className="eiq-card eiq-card-border">{admitContentFlow}</Card>
              </Col>
            </Row>
          )}

          {hasAdmitPermission && isMobileOrTable && admitFlow === null && <Footer>{admitButtons}</Footer>}
        </TableContextProvider>
      )}
    </div>
  );
});
