import React, { FC, useState, useEffect, useMemo } from 'react';
import { Space, Form, Select, SelectProps } from 'antd';
import { IAddress, IStreet } from 'common/services/street-service/street.service.types';
import streetService from 'common/services/street-service/street.service';
import { notification } from 'common/utils/notification';
import { IAddressSelectProps } from './address-select.types';
import '../../../eiq-manage-addresses/components/street-add-edit-modal/street-add-edit-modal.scss';
import { formatZoning } from '../../../../../../common/helpers/address.helper';
import { addressSelectValidation } from './address-select.validation';

const AddressSelect: FC<IAddressSelectProps> = ({ value, setAddress, isOpen, form, initData, disabled }) => {
  const [selectedStreet, setSelectedStreet] = useState<IStreet | undefined>();
  const [streets, setStreets] = useState<IStreet[]>([]);
  const [streetSearch, setStreetSearch] = useState<string>('');

  const [selectedStreetNumber, setSelectedStreetNumber] = useState<string>('');
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [showAddressDetails, setShowAddressDetails] = useState(true);

  useEffect(() => {
    if (initData) {
      setSelectedStreet(initData.street);
      setSelectedStreetNumber(initData.streetNumber);
      setAddress(initData);
    }
    if (!isOpen) {
      resetStreet();
    }
  }, [isOpen, initData]);

  const handleStreetSearch = (newValue: string) => {
    streetService
      .getStreets(newValue ? `name:*` + newValue : null)
      .then((response) => {
        setStreetSearch(newValue);
        setStreets(response.items);
      })
      .catch((e) => {
        notification.error({
          message: 'Can not fetch street list',
        });
        console.log(e);
      })
      .finally(() => {
        resetStreetNumber();
      });
  };

  const formatStreetName = (street: IStreet) => {
    return `${street.name}-${street.community?.name}`;
  };

  const handleStreetChanged = (streetName: string) => {
    const selectedStreet = streets.find((i) => formatStreetName(i) === streetName);
    setSelectedStreet(selectedStreet!);
    resetStreetNumber();
  };

  const resetStreet = () => {
    setStreetSearch('');
    setAddresses([]);
    setSelectedStreet(undefined);
    resetStreetNumber();
  };

  const resetStreetNumber = () => {
    form.resetFields([['address', 'streetNumber']]);
    setSelectedStreetNumber('');
    handleResetAddress();
  };

  const handleStreetNumberChanged = (streetNumber: string) => {
    setSelectedStreetNumber(streetNumber);
    handleResetAddress();
  };

  const handleResetAddress = () => {
    form.resetFields([['address', 'addressId']]);
    setAddress(null);
  };

  useEffect(() => {
    if (selectedStreet) {
      streetService.getAddresses(selectedStreet?.id).then((data) => {
        setAddresses(data);

        // Select address by default
        if (data?.length === 1) {
          setAddress(data[0]);
          form.setFieldsValue({
            address: {
              addressId: data[0].id,
            },
          });
        }
      });
    } else {
      setAddresses([]);
    }
  }, [selectedStreet]);

  useEffect(() => {
    const result = addresses.filter((i) => i.streetNumber === selectedStreetNumber);
    const isSingleStreetAddress =
      result?.length === 1 && !result[0].unit && !result[0].blockNumber && !result[0].buildingNumber && !result[0].lotNumber;

    if (isSingleStreetAddress) {
      setAddress(result[0]);
      setShowAddressDetails(false);
    } else {
      setShowAddressDetails(true);
    }

    const options = result.map((addr) => ({ value: addr.id, label: formatZoning(addr) }));
    setAddressOptions(options);
  }, [selectedStreetNumber, addresses]);

  const streetNumberOptions = useMemo(
    () =>
      addresses
        .map((i) => ({ label: i.streetNumber, value: i.streetNumber }))
        .filter((item, index, self) => index === self.findIndex((o) => o.value === item.value)),
    [addresses],
  );

  return (
    <>
      <Space className="full-width" size={13}>
        <Form.Item
          className="eiq-select select-wrapper"
          name={['address', 'streetName']}
          label="Street name"
          rules={addressSelectValidation.streetName}>
          <Select
            value={selectedStreet?.name}
            disabled={!!initData && disabled}
            defaultActiveFirstOption={false}
            showSearch
            allowClear
            onClear={resetStreet}
            onSearch={handleStreetSearch}
            onDropdownVisibleChange={(isOpen) => isOpen && handleStreetSearch(streetSearch)}
            onChange={handleStreetChanged}
            options={streets.map((i) => ({
              label: i.name,
              value: formatStreetName(i),
            }))}
          />
        </Form.Item>
        <Form.Item
          className="eiq-select select-wrapper"
          name={['address', 'streetNumber']}
          label="Street number"
          rules={addressSelectValidation.streetNumber}>
          <Select
            showSearch
            disabled={disabled && (!selectedStreet || !!initData)}
            allowClear
            onClear={resetStreetNumber}
            defaultActiveFirstOption={false}
            onChange={handleStreetNumberChanged}
            options={streetNumberOptions}
          />
        </Form.Item>
      </Space>

      {showAddressDetails && (
        <Form.Item className="eiq-select" label="Address details" name={['address', 'addressId']} rules={addressSelectValidation.address}>
          <Select
            showSearch
            value={value}
            disabled={disabled && (!selectedStreetNumber || !!initData)}
            allowClear
            onClear={handleResetAddress}
            filterOption={(inputValue, option: any) =>
              inputValue ? option?.label?.toString()?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false : false
            }
            options={addressOptions}
            onChange={(value) => {
              setAddress(addresses?.find((i) => i.id === value) ?? null);
            }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default AddressSelect;
