import { IFeature } from 'common/services/feature-service/feature-service.types';
import React, { FC, useEffect } from 'react';
import { Form, Switch } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import styles from './features.module.scss';
import { notification } from 'common/utils/notification';
import { featureNameMap } from 'common/enums/feature-name.enum';
import featureService from 'common/services/feature-service/feature.service';
import featureStore from 'common/stores/features/feature.store';

interface FeatureItemProps {
  feature: IFeature;
  disabled?: boolean;
}

const FeatureItem: FC<FeatureItemProps> = ({ feature, disabled }) => {
  const [form] = Form.useForm();

  let timeoutId: any;

  const handleFormValuesChange = () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1000);
  };

  const onFinish = async (values: any) => {
    await featureService.toggleFeature(feature.id, values.isEnabled);
    featureStore.toggleFeature(feature.id, values.isEnabled);
    notification.success({
      message: 'Successfully updated',
      duration: 5,
    });
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(feature);
    }
  }, [form, feature]);

  return (
    <FormWrapper form={form} onFinish={onFinish} onValuesChange={handleFormValuesChange} initialValues={feature} layout="vertical">
      <div className={styles.switch}>
        <span>{featureNameMap[feature.name]}</span>
        <Form.Item name="isEnabled" valuePropName="checked">
          <Switch disabled={disabled} />
        </Form.Item>
      </div>
    </FormWrapper>
  );
};

export default FeatureItem;
