export enum FeatureName {
  FastAccess = 'FastAccess',
  SmsNotifications = 'SmsNotifications',
  CA4K = 'CA4K',
  CA3000 = 'CA3000',
  TrafficLogix = 'TrafficLogix',
  Reports = 'Reports',
  Connect = 'Connect',
  CA3000CardClasses = 'CA3000CardClasses',
  CA3000Expirations = 'CA3000Expirations',
  NorthStar = 'NorthStar',
  ConnectEditPin = 'ConnectEditPin',
  CA3KLogsImport = 'CA3KLogsImport',
  CreateResidentAnonymously = 'CreateResidentAnonymously',
}

export const featureNameMap: Record<FeatureName, string> = {
  [FeatureName.FastAccess]: 'EIQ Passport',
  [FeatureName.SmsNotifications]: 'SMS notifications',
  [FeatureName.CA4K]: 'CA4K',
  [FeatureName.CA3000]: 'CA3000',
  [FeatureName.TrafficLogix]: 'Traffic logix',
  [FeatureName.Reports]: 'BI Reports',
  [FeatureName.Connect]: 'EIQ Connect',
  [FeatureName.CA3000CardClasses]: 'CA3000 Card Classes',
  [FeatureName.CA3000Expirations]: 'CA3000 Expirations',
  [FeatureName.NorthStar]: 'North Star',
  [FeatureName.ConnectEditPin]: 'Allow resident edit PIN in EIQ Connect',
  [FeatureName.CA3KLogsImport]: 'Import event logs from CA3000',
  [FeatureName.CreateResidentAnonymously]: 'Enable anonymous access to create residents',
};
